<template>
  <div style="padding: 20px">
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="h2 head-text">Bulk Repayment Approval</p>
    </div>

    <hr style="border: 1.3px solid #e5e5e5" />

    <div class="ma-table">
      <div class="ma-thead">
        <div v-for="(text, i) in thead_texts" :key="i" class="thead-child">
          {{ text }}
        </div>
      </div>

      <hr style="border: 0.6px solid #e5e5e5" />

      <div v-if="bulkRepayments.length > 0">
        <div v-for="(text, i) in bulkRepayments" :key="i">
          <div class="ma-tbody">
            <div
              style="text-align: start; word-wrap: break-word"
              class="tbody-child"
            >
              {{ text.fileName }}
            </div>
            <div class="tbody-child">
              {{ text.numberOfUploads }}
            </div>
            <div class="tbody-child">
              {{ text.success ? text.success : "" }}
            </div>
            <div style="color: #bf0000" class="tbody-child">
              {{ text.failed ? text.failed : "" }}
            </div>
            <div :style="colorType(text.isApproved, text.isDeclined)" class="tbody-child">
              {{ getStatus(text.isApproved, text.isDeclined) }}
            </div>
            <div class="tbody-child text--capital">
              {{ text.createdBy ? text.createdBy : "" }}
            </div>
            <div class="tbody-child dropdown">
              <button
                class="btn dropdown-toggle action"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <router-link
                  :to="`/view-bulk-manager/${text.batchKey}`"
                  tag="a"
                  class="dropdown-item"
                >
                  View
                </router-link>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="openDeleteAttachmentModal(true, text.reportKey)"
                  v-if="!text.isApproved && !text.isDeclined"
                >
                  Approve
                </a>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  v-if="!text.isApproved && !text.isDeclined"
                  @click="openDeleteAttachmentModal(false, text.reportKey)"
                >
                  Decline
                </a>
              </div>
            </div>
          </div>

          <hr style="border: 0.6px solid #e5e5e5" />
        </div>

        <div class="pagination">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              row per page:
              <span
                class="d-inline-block text--primary"
                style="padding-right: 15px"
              >
                <select @change="changeLength" v-model="size">
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="30">30</option>
                  <option :value="50">50</option>
                  <!-- <option :value="paginations.totalCount">All</option> -->
                </select>
              </span>
              <span class="d-inline-block text--primary">{{
                !paginations.hasPrevious
                  ? paginations.currentPage
                  : size * (paginations.currentPage - 1) + 1
              }}</span>
              -
              <span class="d-inline-block text--primary">{{
                paginations.hasNext
                  ? size * paginations.currentPage
                  : paginations.totalCount
              }}</span>
              of
              <span>{{ paginations ? paginations.totalCount : 0 }}</span>
            </div>
            <div
              class="pagination__buttons d-flex align-items-center justify-content-between"
              v-if="paginations"
            >
              <button
                class="button button--sm text--regular"
                @click="changePage('previous')"
                :disabled="!paginations.hasPrevious"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                v-for="page in paginations.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ':
                    paginations.currentPage === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button>
              <button
                class="button text--regular button--sm ml-2"
                @click="changePage('next')"
                :disabled="!paginations.hasNext"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="loading && bulkRepayments.length < 1">
        <div class="d-flex align-items-center justify-content-center p5">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text--black text--600 text--medium d-inline-flex ml-4"
            >Please wait</span
          >
        </div>
      </div>

      <div v-else>
        <div
          class="d-flex flex-column align-items-center justify-content-center p5"
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span class="d-block text--danger text--600 text--medium mt-3 mb-5">
            No data available</span
          >
        </div>
      </div>
    </div>

    <DeleteModal :show="deleteAttachment" @close="closeDeleteAttachmentModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              {{ modalHead }} Batch Document
            </h5>
            <p>{{ message }}</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeleteAttachmentModal"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="isDeleting"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes modal__confirm__button--delete fitrem"
                @click="approve"
                v-else
              >
                {{ modalHead }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import apiService from "@/core/services/api.loan.service";
import { GET_ALL_BULK_REPAYMENT } from "../../../../core/services/store/bulkrepayment.module";
// import ApproveModal from "@components/ApproveModal/approve_modal.vue";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";

export default {
  name: "BulkRepaymentApproval",
  components: {
    DeleteModal,
  },
  data() {
    return {
      thead_texts: [
        "Batch Name",
        "Uploaded",
        "Successful",
        "Failed",
        "Approval Status",
        "Requested By",
        "Action",
      ],
      submitting: false,
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      pages: 1,
      size: 5,
      deleteAttachment: false,
      message: "",
      modalHead: "",
      approveKey: "",
      isDeleting: false,
      status: "",
      viewData: {},
    };
  },

  computed: {
    ...mapState({
      bulkRepayments: (state) => {
        return state.bulkrepayment.allBulkRepayments.data;
      },
      paginations: (state) => {
        return state.bulkrepayment.allBulkRepayments;
      },
      loading: (state) => {
        return state.bulkrepayment.loading;
      },
    }),
  },

  methods: {
    getBulkRepaymentsData() {
      this.$store.dispatch(GET_ALL_BULK_REPAYMENT, {
        pageindex: this.pages,
        pagesize: this.size,
      });
    },

    changePage(type) {
      if (type === "next") {
        this.pages++;
      } else if (type === "previous") {
        this.pages--;
      } else {
        this.pages = type;
      }

      this.getBulkRepaymentsData();
    },

    changeLength(e) {
      const { value } = e.target;
      this.size = parseInt(value);

      this.getBulkRepaymentsData();
    },

    closeDeleteAttachmentModal() {
      this.deleteAttachment = false;
      this.approveKey = "";
      this.status = "";
    },

    openDeleteAttachmentModal(status, key) {
      if (status == true) {
        this.message =
          "Please confirm that you want to approve this document file";
        this.modalHead = "Approve";
      } else {
        this.message =
          "Please confirm that you want to decline this document file";
        this.modalHead = "Decline";
      }
      this.approveKey = key;
      this.deleteAttachment = true;
      this.status = status;
    },

    colorType(status, declined) {
      if (declined) return "color:#DE791B";
      if (status) return "color:#000000";
      return "color:#DE791B";
    },

    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    approve() {
      this.isDeleting = true;
      apiService
        .put(
          `RepaymentBatchUpload/approveBulkBatchRepayment/${this.approveKey}/${this.status}`,
          { reportKey: this.approveKey, status: this.status }
        )
        .then((response) => {
          this.getBulkRepaymentsData();
          this.alertSuccess(response.data.message);
          this.deleteAttachment = false;
          this.isDeleting = false;
          this.closeDeleteAttachmentModal();
        })
        .catch((err) => {
          // this.deleteAttachment = false;
          this.isDeleting = false;
          this.alertError(err.response.data.message);
        });
    },

    getStatus(status, isDecline) {
      if (isDecline) return "Declined";
      if (status) return "Approved";
      return "Pending";
    },
  },

  mounted() {
    this.getBulkRepaymentsData();
  },
};
</script>

<style scope>
.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
}
.dash-box {
  width: 75%;
  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  height: 73px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.hid-box {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 13%;
  text-align: center;
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 13%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ma-thead,
.ma-tbody {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.ma-table {
  display: flex;
  flex-direction: column;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
</style>
